module.exports = [{
      plugin: require('/home/circleci/page/src/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144040671-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/___/**","/__/**","/_/**","/admin/","/callback/","/theme/"],"pageTransitionDelay":1000,"name":"ℊÅ","sampleRate":100,"siteSpeedSampleRate":10,"cookieName":"ℊÅ","cookieDomain":"mingram.net","allowLinker":true,"anonymizeIp":true,"forceSSL":true,"transport":"beacon"},
    },{
      plugin: require('/home/circleci/page/src/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"classes":false,"events":false,"google":{"families":["Sintony","Headland One"]}},
    },{
      plugin: require('/home/circleci/page/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"themeColor","showSpinner":false},
    },{
      plugin: require('/home/circleci/page/src/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/page/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://websites.mingram.net"},
    },{
      plugin: require('/home/circleci/page/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"importScripts":["swaddition.js"],"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"networkFirst","options":{"cacheName":"rest-api-cache","expiration":{"maxAgeSeconds":2678400},"backgroundSync":{"name":"rest-api-queue","options":{"maxRetentionTime":2678400}}}},{"urlPattern":{},"handler":"networkFirst","options":{"cacheName":"graphql-api-cache","expiration":{"maxAgeSeconds":2678400},"backgroundSync":{"name":"graphql-api-queue","options":{"maxRetentionTime":2678400}}}}]},
    },{
      plugin: require('/home/circleci/page/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
