// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/circleci/page/src/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-tsx": () => import("/home/circleci/page/src/src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/circleci/page/src/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-licences-tsx": () => import("/home/circleci/page/src/src/pages/_/licences.tsx" /* webpackChunkName: "component---src-pages-licences-tsx" */),
  "component---src-pages-privacy-tsx": () => import("/home/circleci/page/src/src/pages/_/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("/home/circleci/page/src/src/pages/_/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-admin-tsx": () => import("/home/circleci/page/src/src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-callback-tsx": () => import("/home/circleci/page/src/src/pages/callback.tsx" /* webpackChunkName: "component---src-pages-callback-tsx" */),
  "component---src-pages-theme-tsx": () => import("/home/circleci/page/src/src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */)
}

